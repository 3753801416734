.speckle-masthead {
	align-items: center;
	background: $gradient--primary-to-secondary;
	color: $color--text-on-dark;
	display: flex;
	flex-flow: column;
	justify-content: center;
	min-height: 90vh;

	&::before,
	&::after {
		@include clearfix;
	}

	&__inner {
		padding: 96px 0 64px;
		position: relative;
		text-align: center;
	}

	&__title {
		font-size: 3em;
		margin: 0;
		position: relative;
		z-index: 2;

		@media screen and (min-width: 768px) {
			font-size: 4em;
		}

		a {
			color: inherit;
			outline: 0;
			text-decoration: none;
		}
	}

	&__tagline {
		@include contain('tablet');
		font-size: 1.28em;
		max-width: 400px;
		position: relative;
		z-index: 2;

		@media screen and (min-width: 768px) {
			font-size: 1.64em;
			max-width: 528px;
		}
	}

	&__links {
		color: $color--text-on-dark;
		position: absolute;
		right: $gutter--phone;
		top: $gutter--phone;
		z-index: 1;

		@media screen and (min-width: 768px) {
			right: $gutter--tablet;
			top: $gutter--tablet;
		}

		@media screen and (min-width: 1200px) {
			right: $gutter--desktop;
			top: $gutter--desktop;
		}

	}

	&__link {
		display: inline-block;
		margin-left: 24px;
		vertical-align: middle;

		img {
			display: block;
			height: 40px;
			width: auto;
		}

		&--npm {
			img {
				height: 32px;
				margin-top: 4px;
			}
		}
	}

	&__anchors {
		margin-top: 24px;
		position: relative;
		z-index: 10;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	&__anchors-sep {
		margin: 0 8px;
		opacity: 0.64;
	}

	.speckle-button {
		margin-top: 8px;
		z-index: 8;
	}
}
