.speckle-section {
	overflow-x: hidden;
	padding-bottom: 80px;
	padding-top: 80px;

	@media screen and (min-width: 768px) {
		padding-bottom: 96px;
		padding-top: 96px;
	}

	&__inner {
		@include contain('desktop');
		max-width: 960px;

		&::before,
		&::after {
			@include clearfix;
		}
	}

	&__scroller {
		box-sizing: border-box;
		overflow-x: auto;
	}

	&--options {
		background: $gradient--secondary-to-primary;
	}

	h1,
	h2 {
		color: $color--primary;
		display: inline-block;
		font-size: 2.25em;
		font-weight: bold;
		margin-top: -16px;
	}

}
