.speckle-button {
	background-color: $color--primary;
	color: $color--text-on-primary;
	display: inline-block;
	font-size: 1.25em;
	line-height: 3em;
	padding: 0 1.48em;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}
